import slugify from "slugify";
import cn from "classnames";

import type { ButtonProps } from "./types";

import styles from "./Button.module.scss";
import { Icon } from "../Icon/Icon";
import { BaseButton } from "./BaseButton/BaseButton";

const Button = ({
  size = "large",
  variant = "primary",
  text = "",
  id,
  smallScreenText,
  hiddenText,
  onClick,
  icon,
  isPremium = false,
  limitWidth,
  slug,
  href,
  type = "button",
  isExternalLink,
  className,
  tabIndex,
}: ButtonProps) => {
  const dataSlug = slug ? slug : slugify(text, { lower: true, locale: "se" });

  return (
    <BaseButton
      onClick={onClick}
      href={href}
      size={size}
      id={id}
      variant={variant}
      slug={dataSlug}
      isPremium={isPremium}
      type={type}
      className={className}
      isExternalLink={isExternalLink}
      tabIndex={tabIndex}
      content={
        <span
          className={cn([
            styles.buttonContent,
            limitWidth && styles.limitWidth,
          ])}
        >
          {icon && (
            <Icon
              variant={icon}
              inPrimaryButtonContext={variant === "primary"}
              ariaLabel={hiddenText}
            />
          )}
          {text && (
            <>
              {smallScreenText ? (
                <>
                  <span className={cn([styles.buttonText, styles.default])}>
                    {text}
                  </span>
                  <span className={cn([styles.buttonText, styles.smallScreen])}>
                    {smallScreenText}
                  </span>
                </>
              ) : (
                <span className={styles.buttonText}>{text}</span>
              )}
            </>
          )}
          {!text && hiddenText && (
            <span className={cn([styles.buttonText, styles.hidden])}>
              {hiddenText}
            </span>
          )}
        </span>
      }
    />
  );
};

export { Button };
