const CheckboxSelected = ({ ariaLabel }: { ariaLabel?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      aria-label={ariaLabel}
    >
      <path
        fill="#222"
        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m0 14.667A6.666 6.666 0 1 1 8 1.334a6.666 6.666 0 0 1 0 13.333"
      />
      <path
        fill="#222"
        d="M12.202 8a4.202 4.202 0 1 1-8.404 0 4.202 4.202 0 0 1 8.404 0"
      />
    </svg>
  );
};

export { CheckboxSelected };
